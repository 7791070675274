/* eslint-disable react/prop-types */
import React, { useState } from 'react';
import Badge from '@cloudscape-design/components/badge';
import { Box, ColumnLayout, Container, Header, SpaceBetween } from '@cloudscape-design/components';
import AudioPlaybackWidget from './audioPlaybackWidget';
import AudioTranscriptDisplay from './audioTranscriptDisplay';
import { ContactRecord } from '../../../../../types/contactRecord';
import { ValueWithLabel } from '../../../../../components/valueWithLabel';

interface CategoryCounts {
  [key: string]: number;
}

export interface ContactAnalysisVoiceProps {
  contactObject: ContactRecord;
}

export default function ContactAnalysisVoice(props: ContactAnalysisVoiceProps) {
  const { contactObject } = props;
  const [audioPosition, setAudioPosition] = useState<number>(200);
  // create an object that maps overs the MatchedCategories array such that "greeting (2)" can be displayed.
  const categoryCounts: CategoryCounts = {};
  contactObject?.categories?.MatchedCategories?.forEach((item: string) => {
    if (categoryCounts[item]) {
      categoryCounts[item] += 1;
    } else {
      categoryCounts[item] = 1;
    }
  });

  // Create an object to get the Issues Detected text to display if present
  let issueTexts: any | null = [];
  contactObject?.transcript?.forEach((entry) => {
    entry?.IssuesDetected?.forEach((issue) => {
      issueTexts.push(issue.Text);
    });
  });

  // if there are no issues, set issueTexts to null
  if (issueTexts.length === 0) issueTexts = null;

  return (
    <Container header={<Header headingTagOverride='h3'>Contact Analysis</Header>}>
      <ColumnLayout
        columns={3}
        variant='text-grid'
      >
        <SpaceBetween size='xs'>
          <ValueWithLabel label='Talk speed - Agent'>
            {contactObject.conversationCharacteristics?.TalkSpeed?.DetailsByParticipant?.AGENT?.AverageWordsPerMinute ?? '-'}
          </ValueWithLabel>
          <ValueWithLabel label='Talk speed - Customer'>
            {contactObject.conversationCharacteristics?.TalkSpeed?.DetailsByParticipant?.CUSTOMER?.AverageWordsPerMinute ?? '-'}
          </ValueWithLabel>
          <ValueWithLabel label='Overall Sentiment - Agent'>
            {contactObject.conversationCharacteristics?.Sentiment?.OverallSentiment?.AGENT ?? '-'}
          </ValueWithLabel>
          <ValueWithLabel label='Overall Sentiment - Customer'>
            {contactObject.conversationCharacteristics?.Sentiment?.OverallSentiment?.CUSTOMER ?? '-'}
          </ValueWithLabel>
        </SpaceBetween>
        <SpaceBetween size='xs'>
          <ValueWithLabel label='Total Interruptions'>{contactObject.conversationCharacteristics?.Interruptions?.TotalCount ?? '-'}</ValueWithLabel>
          <ValueWithLabel label='Silence'>
            {contactObject?.conversationCharacteristics?.NonTalkTime && contactObject?.conversationCharacteristics?.TotalConversationDurationMillis
              ? `${Math.round(
                  (contactObject.conversationCharacteristics.NonTalkTime.TotalTimeMillis /
                    contactObject.conversationCharacteristics.TotalConversationDurationMillis) *
                    100,
                )}%`
              : '-'}
          </ValueWithLabel>
        </SpaceBetween>
        <SpaceBetween size='xs'>
          <Box
            variant='h3'
            padding='n'
          >
            Matched Categories
          </Box>
          <div style={{ display: 'flex', flexWrap: 'wrap', gap: '10px' }}>
            {Object.keys(categoryCounts).length > 0 ? (
              Object.entries(categoryCounts).map(([category, count], index) => (
                // eslint-disable-next-line react/no-array-index-key
                <Badge
                  color='blue'
                  key={index}
                >
                  {category}
                  {count > 1 ? ` (${count})` : ''}
                </Badge>
              ))
            ) : (
              <p>No Matched Categories.</p>
            )}
          </div>
          <Box
            variant='h3'
            padding='n'
          >
            Issues Detected
          </Box>
          {issueTexts ? (
            issueTexts.map((text: any, index: any) => (
              // eslint-disable-next-line react/no-array-index-key
              <Badge
                color='red'
                key={index}
              >
                {text}
              </Badge>
            ))
          ) : (
            <p>No issues detected.</p>
          )}
        </SpaceBetween>
      </ColumnLayout>
      <ColumnLayout
        columns={1}
        variant='text-grid'
      >
        {contactObject?.inputS3Uri ? (
          <SpaceBetween size='xs'>
            <Box
              variant='h3'
              padding='xl'
            >
              Audio playback
            </Box>
            <AudioPlaybackWidget
              contactObject={contactObject}
              setAudioPosition={setAudioPosition}
              audioURL={!contactObject?.inputS3Uri && process.env.REACT_APP_ENV !== 'prod' ? 'demo' : contactObject?.inputS3Uri?.replace('s3://', '') || ''}
            />
          </SpaceBetween>
        ) : (
          <SpaceBetween size='xs'>
            <Box
              variant='h3'
              padding='xl'
            >
              No Call Recording Available
            </Box>
          </SpaceBetween>
        )}
        {contactObject?.transcript?.length > 0 && (
          <SpaceBetween size='xs'>
            <Box
              variant='h3'
              padding='xl'
            >
              Transcription
            </Box>
            <AudioTranscriptDisplay
              transcript={contactObject?.transcript}
              audioPosition={audioPosition}
            />
          </SpaceBetween>
        )}
      </ColumnLayout>
    </Container>
  );
}
