export const hasOwnProperty = <T = any>(object: T, property: string) => {
  if (object === undefined || object === null) return false;
  return Object.prototype.hasOwnProperty.call(object, property);
};

/**
 * Type safe Object.keys() invocation
 * @param object
 * @returns
 */
export const objectKeys = <T extends object>(object: T) => Object.keys(object) as Array<Extract<keyof T, string>>;

/**
 * Type safe Object.values() invocation
 * @param object
 * @returns
 */
export const objectValues = <T extends object>(object: T) => Object.values(object) as Array<T[keyof T]>;
