import {
  Container,
  FormField,
  SpaceBetween,
  Spinner,
  Textarea,
} from "@cloudscape-design/components";
import React, { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faLanguage } from "@fortawesome/free-solid-svg-icons";
import TranslationModal from "../../../../components/messages/translationModal";

export default function ClosureMessages({
  messages,
  setItem,
  messageKey,
}: any) {
  const [isModalOpen, setModalOpen] = useState(false);
  const [selectedMessageKey, setSelectedMessageKey] = useState<string | null>(
    null,
  );

  const openModalWithMessageKey = (key: string) => {
    setSelectedMessageKey(key);
    setModalOpen(true);
  };

  function handleUpdatedMessage(newValue: string) {
    setItem((prevState: any) => {
      // Deep clone the existing data to avoid direct mutation
      const updatedMessages = JSON.parse(
        JSON.stringify(prevState.data[messageKey].message),
      );

      // Modify the cloned data
      updatedMessages[0].message[0].children[0].text = newValue;

      // Build the new state
      const updatedData = {
        ...prevState.data,
        [messageKey]: {
          ...prevState.data[messageKey],
          message: updatedMessages,
        },
      };

      // Return the new state
      return {
        ...prevState,
        data: updatedData,
      };
    });
  }

  return (
    <Container>
      <SpaceBetween direction="vertical" size="xs">
        <FormField
          description="The default message"
          label="Message"
          stretch={true}
        >
          <Textarea
            onChange={({ detail }) => {
              handleUpdatedMessage(detail.value);
            }}
            value={
              messages ? (
                messages[0]?.message[0]?.children[0]?.text
              ) : (
                <Spinner />
              )
            }
            placeholder="Enter a closure message"
          />
        </FormField>
        <FormField
          description="Manage the associated translations for the default message"
          label="Translations"
        >
          <FontAwesomeIcon
            icon={faLanguage}
            style={{ cursor: "pointer" }}
            onClick={() => openModalWithMessageKey(messageKey)}
            title="View Translations"
          />
        </FormField>
      </SpaceBetween>
      {selectedMessageKey && (
        <TranslationModal
          messageKey={messageKey}
          messages={messages}
          setItem={setItem}
          open={isModalOpen}
          setOpen={setModalOpen}
        />
      )}
    </Container>
  );
}
