import { StatusIndicator, Select, SelectProps, TableProps, CollectionPreferencesProps, Popover, KeyValuePairs } from '@cloudscape-design/components';
import { getAttributeValue, getStatusIndicatorType, userRoleStringToRoles } from '../utils/helpers';
import { useAppSession } from '../../../utils/hooks/sessionContext';
import { User, UserStatuses } from '../types';
import { PreferenceProps } from '../../../types/table';
import { TablePreferences } from '../../../utils/tablePreferences';
import { RoleRecordsList } from '../../../types/rolePermissions';

export interface UseTableConfigProps {
  roles: RoleRecordsList;
}

export const useTableConfig = ({ roles }: UseTableConfigProps) => {
  const appSession = useAppSession();

  const columns: TableProps.ColumnDefinition<User>[] = [
    {
      id: 'username',
      header: 'Username',
      cell: (user) => user.Username,
      sortingField: 'Username',
    },
    {
      id: 'name',
      header: 'Full Name',
      cell: (user) => {
        const givenName = getAttributeValue(user.Attributes, 'given_name') ?? '';
        const familyName = getAttributeValue(user.Attributes, 'family_name') ?? '';
        return givenName || familyName ? `${givenName} ${familyName}`.trim() : '-';
      },
    },
    {
      id: 'status',
      header: 'Account Status',
      cell: (user) => {
        const statusType = getStatusIndicatorType(user.UserStatus);
        return <StatusIndicator type={statusType}>{user.UserStatus}</StatusIndicator>;
      },
      sortingField: 'UserStatus',
    },
    {
      id: 'status2',
      header: 'User Status',
      cell: (user) => {
        const statusType = user.Enabled ? 'success' : 'error';
        return <StatusIndicator type={statusType}>{user.Enabled ? 'Active' : 'Disabled'}</StatusIndicator>;
      },
      sortingField: 'Enabled',
    },
    {
      id: 'securityProfile',
      header: 'Security Profile',
      minWidth: 176,
      cell: (user) => {
        return getAttributeValue(user.Attributes, 'custom:securityProfile');
      },
      editConfig: {
        ariaLabel: 'Security Profile',
        errorIconAriaLabel: 'Security Profile Validation Error',
        editIconAriaLabel: 'editable',
        editingCell: (user, { setValue, currentValue }) => {
          const value = currentValue ?? getAttributeValue(user.Attributes, 'custom:securityProfile');

          const selectProps: SelectProps = {
            autoFocus: true,
            expandToViewport: true,
            ariaLabel: 'Select desired security profile',
            selectedOption:
              [
                { label: 'Administrator', value: 'Administrator' },
                { label: 'User', value: 'User' },
              ].find((option) => option.value === value) ?? null,
            onChange: (event) => {
              setValue(event.detail.selectedOption.value);
            },
            options: [
              { label: 'Administrator', value: 'Administrator' },
              { label: 'User', value: 'User' },
            ],
          };

          return <Select {...{ ...selectProps }} />;
        },
        disabledReason: (item) => {
          if (item.Username === appSession?.userProfile?.email) {
            return 'Editing not allowed on self';
          }
          if (item.UserStatus === UserStatuses.ExternalProvider) {
            return 'Editing not allowed on external providers';
          }
          return undefined;
        },
      },
    },
    {
      id: 'roles',
      header: 'Roles',
      cell: (user) => {
        const userRoleString = getAttributeValue(user.Attributes, 'custom:roles') ?? '';

        const userRoles = userRoleStringToRoles(userRoleString, roles);

        return userRoles.length ? (
          <Popover
            header={`${user.Username}'s Roles`}
            wrapTriggerText={false}
            size="large"
            content={
              <KeyValuePairs
                columns={2}
                items={userRoles.map((role) => ({ label: role.name, value: role.description }))}
              />
            }
          >
            <>{userRoles.map(r => r.name).join(', ')}</>
          </Popover>
        ) : '';
      },
    },
  ];

  const defaultPreferences: CollectionPreferencesProps.Preferences = {
    pageSize: 10,
    contentDisplay: [
      { id: 'username', visible: true },
      { id: 'name', visible: true },
      { id: 'status', visible: true },
      { id: 'status2', visible: true },
      { id: 'securityProfile', visible: true },
      { id: 'roles', visible: false },
    ],
    wrapLines: false,
    stripedRows: false,
    contentDensity: 'comfortable',
    stickyColumns: { first: 1, last: 0 },
  };

  return {
    columns,
    defaultPreferences,
  };
};

const CONTENT_DISPLAY_OPTIONS = [
  { id: 'username', label: 'Username', alwaysVisible: true },
  { id: 'name', label: 'Full Name' },
  { id: 'status', label: 'Users Status' },
  { id: 'status2', label: 'Account Status' },
  { id: 'securityProfile', label: 'Security Profile' },
  { id: 'roles', label: 'Roles' },
];

const PAGE_SIZE_OPTIONS = [
  { value: 10, label: '10 Users' },
  { value: 30, label: '30 Users' },
  { value: 50, label: '50 Users' },
];

export const Preferences = ({
  preferences,
  setPreferences,
  disabled = false,
  pageSizeOptions = PAGE_SIZE_OPTIONS,
  contentDisplayOptions = CONTENT_DISPLAY_OPTIONS,
}: PreferenceProps) => <TablePreferences {...{ preferences, setPreferences, disabled, pageSizeOptions, contentDisplayOptions }} />;
