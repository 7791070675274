import { isDateExpired } from "./isDateExpired";
import { PlannedClosure } from "../types/closures";

/**
 * Sorts an array of events by date, putting future and current dates first,
 * followed by past dates, all in ascending order.
 *
 * @param {Array} events - Array of event objects to sort.
 * @return {Array} - Sorted array of events.
 */
export default function sortEvents (events: PlannedClosure[], timezone: string): PlannedClosure[] {
  return events.sort((a, b) => {
    // First, sort by expiration status
    const aExpired = isDateExpired(a, timezone);
    const bExpired = isDateExpired(b, timezone);
    
    if (aExpired !== bExpired) {
      return aExpired ? 1 : -1;
    }

    // If expiration status is the same, sort by date
    return new Date(a.date).getTime() - new Date(b.date).getTime();
  });
}
