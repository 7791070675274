import React from 'react';

interface TrendInfoProps {
    trend: number | null;
    value: number | null;
    higherIsBetter?: boolean;
}

export const TrendInfo: React.FC<TrendInfoProps> = ({trend, value, higherIsBetter = true}) => {
    const getColor = () => {
        if (trend === value) return 'grey';
        if (trend !== null && value !== null) {
            return trend > value ? (higherIsBetter ? 'green' : 'red') : (higherIsBetter ? 'red' : 'green');
        }
        return 'grey';
    };

    return (
        <div style={{marginLeft: '0px', display: 'flex', flexDirection: 'row', alignItems: 'center'}}>
            <div style={{fontSize: '2em', marginLeft: '0px', display: 'flex', alignItems: 'baseline'}}>
    <span style={{color: getColor()}}>
      {trend !== null ? trend : 'N/A'}
    </span>
                <span style={{fontSize: '0.6em', marginLeft: '5px', color: getColor()}}>
      {trend === value ? '-' : (trend !== null && value !== null && trend > value) ? '↓' : '↑'}
    </span>
            </div>
        </div>

    );
};
