import * as React from 'react';
import Select from '@cloudscape-design/components/select'; // Make sure to import Select component properly
import useIntuitionSWR from '../../utils/hooks/useIntuitionSWR';
import { useItemContext } from '../../routes/visualizations/view/dashboardContext';

type Queue = {
  Id: string;
  Arn: string;
  Name: string;
  QueueType: string;
};

interface QueuePickerSingleResponse {
  data: Queue[];
}

type OptionDefinition = {
  label: string;
  value: string;
  description: string;
};

interface SingleQueuePickerProps {
  widgetId: string;
}

const QueuePickerSingle: React.FC<SingleQueuePickerProps> = ({ widgetId }) => {
  const { item, getWidget, setItem } = useItemContext();
  const [selectedOption, setSelectedOption] = React.useState<OptionDefinition | null>(null);

  const { data, error } = useIntuitionSWR<QueuePickerSingleResponse>(`/instance-config/queues`, {
    revalidateOnFocus: false,
    revalidateOnReconnect: false,
    refreshInterval: 0,
  });

  const isLoading = !data && !error;

  const transformQueueData = (queues: Queue[]): OptionDefinition[] => {
    return queues.map((queue) => ({
      label: queue.Name,
      value: queue.Arn,
      description: queue.QueueType,
    }));
  };

  const options = React.useMemo(() => {
    return isLoading || !data ? [] : transformQueueData(data.data);
  }, [isLoading, data]);

  React.useEffect(() => {
    const currentWidget = getWidget(widgetId);
    if (currentWidget && currentWidget.filters && currentWidget.filters.queues) {
      const queue = options.find((opt) => opt.value === currentWidget.filters?.queues?.[0]);
      if (queue) {
        setSelectedOption(queue);
      }
    }
  }, [item, options, widgetId]);
  const updateWidgetItem = (selectedArns: string[], newTitle?: string) => {
    setItem((prevItem) => {
      const updatedWidgets = prevItem.data.widgets.map((widget) => {
        if (widget.id === widgetId) {
          return {
            ...widget,
            filters: {
              ...widget.filters,
              queues: selectedArns,
            },
            data: {
              ...widget.data,
              title: newTitle || widget.data.title, // Update the title, fallback to existing title
            },
          };
        }
        return widget;
      });

      return {
        ...prevItem,
        data: {
          ...prevItem.data,
          widgets: updatedWidgets,
        },
      };
    });
  };

  const handleSelectionChange = (selectedOpt: OptionDefinition) => {
    setSelectedOption(selectedOpt);
    updateWidgetItem([selectedOpt.value], selectedOpt.label); // Pass selectedOpt.label as second argument
  };

  if (error) {
    return <div>Error loading data</div>;
  }

  return (
    <div>
      <Select
        filteringType='auto'
        onChange={({ detail }) => handleSelectionChange(detail.selectedOption as OptionDefinition)}
        options={options}
        placeholder='Select queue'
        selectedOption={selectedOption}
      />
    </div>
  );
};

export default QueuePickerSingle;
