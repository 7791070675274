import { saveAs } from "file-saver";
import cxStudioSample from "../../../objects/AmznContactFlows/CXStudioSample.json";
import cxStudioSampleOutbound from "../../../objects/AmznContactFlows/CXStudioSampleOutbound.json";

// Create a mapping of type strings to imported JSON objects
const typeMapping = {
  cxStudioSample: cxStudioSample,
  cxStudioSampleOutbound: cxStudioSampleOutbound,
};

function replaceLambdaARN(json, apiArn) {
  const jsonString = JSON.stringify(json);
  const updatedJsonString = jsonString.replace(/{{LambdaARN}}/g, apiArn);
  return JSON.parse(updatedJsonString);
}

// Utility function to initiate the download
export function downloadSampleContactFlow(type, apiArn) {
  // Use the type to get the correct JSON object from the mapping
  const selectedJson = typeMapping[type];
  if (!selectedJson) {
    console.error("Invalid type provided:", type);
    return;
  }

  const updatedJson = replaceLambdaARN(selectedJson, apiArn);
  const blob = new Blob([JSON.stringify(updatedJson)], {
    type: "application/json",
  });
  saveAs(blob, `${type}.json`);
}
