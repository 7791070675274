export enum ResourceTypes {
  Admin = 'admin',
  Closures = 'closures',
  ContactSearch = 'contactSearch',
  Dashboards = 'dashboards',
  DialPlan = 'dialPlan',
  Instance = 'instance',
  Messages = 'messages',
  Profile = 'profile',
  PromptConfig = 'prompt-config',
  Roles = 'roles',
  Rules = 'rules',
  Surveys = 'surveys',
  TenantFile = 'tenant-file',
  Users = 'users',
  Xq = 'xq',
}

export type ResourceType = `${ResourceTypes}`;

export enum Actions {
  Create = 'create',
  Read = 'read',
  Update = 'update',
  Delete = 'delete',
}

export type Action = `${Actions}`;

export type InstancePermissions = {
  [key in ResourceType]?: Action[];
};

export type InstanceEditorPermissions = {
  [action in Actions]: boolean;
};
export type InstanceEditorPermissionsSet = {
  [key in ResourceType]?: InstanceEditorPermissions;
};

export type RolePolicyRecord = {
  instance: string;
  resourceType: ResourceType;
  resource?: string;
  actions: Action[];
};

export type RoleRecord = {
  tenantId: string; // primary key
  feature: string; // sort key
  name: string;
  tags?: string[];
  description: string;
  policies: RolePolicyRecord[];
  instance: string;
};
export type RoleRecordsList = RoleRecord[];
export type RoleApiResponse = {
  Items: Array<RoleRecord>;
  Count: number;
};
