export const FILTERING_PROPERTIES = [
    {
        propertyLabel: 'Username',
        key: 'Username',
        groupValuesLabel: 'Email values',
        operators: [':', '!:', '=', '!='] as const,
    },
    {
        propertyLabel: 'Account Status',
        key: 'UserStatus',
        groupValuesLabel: 'Users Status',
        operators: [':', '!:', '=', '!='] as const,
    },
    {
        propertyLabel: 'User Status',
        key: 'Enabled',
        groupValuesLabel: 'Account Status',
        operators: [':', '!:', '=', '!='] as const,
    },
] as const;
