export const FILTERING_PROPERTIES = [
    {
        propertyLabel: 'Performed by',
        key: 'requester',
        groupValuesLabel: 'requester',
        operators: [':', '!:', '=', '!='] as const,
    },
    {
        propertyLabel: 'Action',
        key: 'action',
        groupValuesLabel: 'action',
        operators: [':', '!:', '=', '!='] as const,
    },
    {
        propertyLabel: 'Resource type',
        key: 'type',
        groupValuesLabel: 'type',
        operators: [':', '!:', '=', '!='] as const,
    },
    {
        propertyLabel: 'Resource name',
        key: 'resource',
        groupValuesLabel: 'resource',
        operators: [':', '!:', '=', '!='] as const,
    },
] as const;
