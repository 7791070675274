import { RoleRecord, RoleRecordsList } from '../../../types/rolePermissions';
import { Attribute } from '../types';

export function getAttributeValue(attributes: Attribute[], name: string): string | undefined {
  const attribute = attributes.find((attr) => attr.Name === name);
  return attribute?.Value;
}

export function getStatusIndicatorType(userStatus: string): 'warning' | 'success' | 'error' {
  const statusMap = new Map<string, 'warning' | 'success'>([
    ['UNCONFIRMED', 'warning'],
    ['CONFIRMED', 'success'],
    ['EXTERNAL_PROVIDER', 'success'],
  ]);

  return statusMap.get(userStatus) ?? 'error';
}

export function userRoleStringToRoles(userRoleString: string, roles: RoleRecordsList): RoleRecord[] {
  return userRoleString
    .split('|')
    .map((roleId) => roles.find((role) => role.feature === roleId))
    .filter((role): role is RoleRecord => !!role);
}
