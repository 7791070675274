import React, { useEffect, useState } from 'react';
import useSWR from 'swr';
import fetcher from '../../utils/fetcher';
import Header from '@cloudscape-design/components/header';
import SpaceBetween from '@cloudscape-design/components/space-between';
import { ContentLayout } from '@cloudscape-design/components';
import UserTable from './components/table';
import { UserProfile, fetchUserProfile } from '../../components/auth/userProfile';
import { ApiResponse } from '../../types/api';
import { RoleApiResponse } from '../../types/rolePermissions';

export default function UserAdmin() {
  const {
    data: usersData,
    isLoading: usersLoading,
    error: usersError,
  } = useSWR('/users', fetcher, {
    revalidateOnFocus: false,
    revalidateOnReconnect: false,
    refreshInterval: 0,
  });

  const {
    data: rolesData,
    isLoading: rolesLoading,
    error: rolesError,
  } = useSWR<ApiResponse<RoleApiResponse>>('/roles', fetcher, {
    revalidateOnFocus: false,
    revalidateOnReconnect: false,
    refreshInterval: 0,
  });

  const [profile, setProfile] = useState<UserProfile | null>(null);

  useEffect(() => {
    async function getUserProfile() {
      const profileData = await fetchUserProfile();
      setProfile(profileData);
    }

    getUserProfile();
  }, []);

  return (
    <ContentLayout
      header={
        <Header
          variant='h1'
          description='Add / remove emails for the users within the domain to control their access.'
        >
          Manage application users
        </Header>
      }
    >
      <SpaceBetween size='m'>
        <UserTable
          isLoading={usersLoading || rolesLoading}
          error={usersError || rolesError}
          users={usersData?.data}
          roles={rolesData?.data?.Items}
          currentUser={profile?.username ?? ''}
        />
      </SpaceBetween>
    </ContentLayout>
  );
}
