import React from 'react';
import {Box, Button, SpaceBetween, Modal} from '@cloudscape-design/components'; // Assuming you are using this component library.
import TranslationsTable from "./translationTable";

type TranslationsModalProps = {
    visible: boolean;
    setVisible: (visible: boolean) => void;
    messageKey: string;
    item: any;
    setItem: any;
    messagesPath?: string;
};

const TranslationsModal: React.FC<TranslationsModalProps> = ({visible, setVisible, messageKey, item, setItem, messagesPath}) => {
    console.log("TranslationsModal", messageKey, item)
    return (
        <Modal
            onDismiss={() => setVisible(false)}
            visible={visible}
            size="max"
            footer={
                <Box float="right">
                    <SpaceBetween direction="horizontal" size="xs">
                        <Button variant="link" onClick={() => setVisible(false)}>Close</Button>
                    </SpaceBetween>
                </Box>
            }
        >
            <TranslationsTable messageKey={messageKey} item={item} setItem={setItem} messagesPath={messagesPath}/>
        </Modal>
    );
}

export default TranslationsModal;
