import React, { useState, useEffect } from 'react';
import {
  ColumnLayout,
  Container,
  Header,
  ContentLayout,
  SpaceBetween,
  Textarea,
  Form,
  Button,
  FormField,
  Spinner,
  Autosuggest,
  AutosuggestProps,
  ButtonProps,
  TextareaProps,
  Input,
} from '@cloudscape-design/components';
import { useNavigate, useParams } from 'react-router-dom';
import tzList from '../../../objects/tz';
import { SingleClosureAPIResponse } from '../../../types/closures';
import { handleFieldChange } from '../../../utils/fieldChangeUtils';
import { useApiWithFlash } from '../../../utils/hooks/useApiWithFlashInstance';
import useInstanceSWR from '../../../utils/hooks/useInstanceSWR';
import useChangeDetector from '../../../utils/useChangeDetector';
import OpeningHours, { OpeningHoursProps } from './components/openingHours';
import PlannedClosures, { PlannedClosuresProps } from './components/plannedClosures';
import UnplannedClosures, { UnplannedClosuresProps } from './components/unplannedClosures';
import { ConfirmCancelModal } from '../../../components/confirmCancelModal';
import { TagIcon } from '../../utilities/tagIcon';
import { isValidTag } from '../../../utils/validations';
import { useBlockerLogic } from '../../../utils/hooks/useBlocker';

export default function ClosuresEdit() {
  const { item, setItem, setItemShadow, changesDetected } = useChangeDetector<SingleClosureAPIResponse>({} as SingleClosureAPIResponse);
  const [formSubmitted, setFormSubmitted] = useState<boolean>(false);
  const [timezone, setTimezone] = useState<string>('');
  const navigate = useNavigate();
  const { featureId } = useParams();
  const { handleApiWithFlash } = useApiWithFlash();
  const [tagError, setTagError] = useState<boolean>(false);
  const [tagErrorMessage, setTagErrorMessage] = useState<string>();
  const { blocker, handleCancel, handleCloseCancelModal, handleConfirmCancel } = useBlockerLogic({
    changesDetected,
    path: 'closures',
    formSubmitted,
  });

  const { data, isLoading, error } = useInstanceSWR(`/closures/${featureId}`, {
    revalidateOnFocus: false,
    revalidateOnReconnect: false,
    refreshInterval: 0,
  });

  useEffect(() => {
    if (data?.data) {
      setItem(data.data);
      setItemShadow(data.data);
      setTimezone(data.data.data.timezone);
    }
  }, [data]);

  if (error) return <div>Error loading data</div>;
  if (!data || !item.feature) return <div>Loading...</div>;

  const hoops = item?.data?.hoops ?? [];
  const plannedClosures = item?.data?.plannedClosures ?? [];
  const unplannedClosure = item?.data?.unplannedClosure ?? [];

  // This is used by the other fields in the form to update local state
  const setItemFieldChange = handleFieldChange(setItem);
  const validateTag = (value: string) => {
    // Define patterns
    const validTag = isValidTag(value);

    if (!validTag) {
      setTagError(true);
      setTagErrorMessage('Only lowercase alphanumeric, - _ and + supported');
    } else {
      setTagError(false);
      setTagErrorMessage('');
    }
  };
  async function handleFormSubmit() {
    setFormSubmitted(true);
    await handleApiWithFlash(`/closures/${item.feature}`, 'PUT', {
      successMessage: `Successfully updated closure group ${item.feature}`,
      errorMessage: `Error updating closure group ${item.feature}`,
      mutateKey: `/closures/${item.feature}`,
      data: item,
    });
    setItemShadow(item);
    navigate('/closures');
  }
  const handleTagChange = (tag: string) => {
    setItemFieldChange('tag', tag);
    validateTag(tag);
  };
  const validatePlannedClosures = () => {
    return plannedClosures.every((closure) => closure.date && closure.startTime && closure.endTime);
  };

  const canSubmit = changesDetected && !tagError && validatePlannedClosures();

  const timezoneSuggestProps: AutosuggestProps = {
    onChange: ({ detail }) => {
      setTimezone(detail.value);
      const isInList = !!tzList.find((timezoneItem) => timezoneItem.value === detail.value);
      isInList && setItem({ ...item, data: { ...item.data, timezone: detail.value } });
    },
    value: timezone,
    options: tzList,
    ariaLabel: 'Select a timezone for this closure group',
    placeholder: 'Enter a timezone',
  };

  const closureButtonProps: ButtonProps = {
    formAction: 'none',
    variant: 'link',
    onClick: handleCancel,
  };

  const submitButtonProps: ButtonProps = {
    variant: 'primary',
    loading: formSubmitted,
    onClick: handleFormSubmit,
    disabled: !canSubmit,
  };

  const openingHoursProps: OpeningHoursProps = {
    hoops,
    setItem,
  };

  const plannedClosuresProps: PlannedClosuresProps = {
    plannedClosures,
    setItem,
    item,
  };

  const unplannedClosuresProps: UnplannedClosuresProps = {
    unplannedClosure,
    setItem,
  };

  const descriptionTextAreaProps: TextareaProps = {
    onChange: ({ detail }) => setItemFieldChange('description', detail.value),
    value: item?.description,
  };

  return (
    <ContentLayout header={<Header variant='h1'></Header>}>
      <Form
        actions={
          <SpaceBetween
            direction='horizontal'
            size='xs'
          >
            <Button {...closureButtonProps}>Cancel</Button>
            <Button {...submitButtonProps}>Submit</Button>
          </SpaceBetween>
        }
      >
        <SpaceBetween
          direction='vertical'
          size='xl'
        >
          <Container header={<Header variant='h2'>{isLoading ? <Spinner /> : item?.feature}</Header>}>
            <ColumnLayout
              columns={2}
              variant='text-grid'
            >
              <FormField label='Timezone'>
                <Autosuggest {...{ ...timezoneSuggestProps }} />
              </FormField>
              <FormField
                constraintText={'Only alphanumeric, - _ and + supported'}
                errorText={tagErrorMessage}
                label={
                  <>
                    Tag your closure group <TagIcon />
                  </>
                }
              >
                <Input
                  onChange={({ detail }) => handleTagChange(detail.value)}
                  value={item.tag ?? ''}
                />
              </FormField>
            </ColumnLayout>
            <FormField
              label='Description'
              stretch
            >
              <Textarea {...{ ...descriptionTextAreaProps }} />
            </FormField>
          </Container>
          <OpeningHours {...{ ...openingHoursProps }} />
          <PlannedClosures {...{ ...plannedClosuresProps }} />
          <UnplannedClosures {...{ ...unplannedClosuresProps }} />
        </SpaceBetween>
      </Form>
      {blocker.state === 'blocked' && (
        <ConfirmCancelModal
          onCancel={handleCloseCancelModal}
          onConfirm={handleConfirmCancel}
          cancelMessage='Are you sure you want to close? Any unsaved changes will be lost.'
        />
      )}
    </ContentLayout>
  );
}
