import { useEffect, useState } from 'react';
import SpaceBetween from '@cloudscape-design/components/space-between';
import Button from '@cloudscape-design/components/button';
import Header from '@cloudscape-design/components/header';
import ButtonDropdown, { ButtonDropdownProps } from '@cloudscape-design/components/button-dropdown';
import { Pagination, Spinner, Table, TableProps, Modal } from '@cloudscape-design/components';
import PropertyFilter from '@cloudscape-design/components/property-filter';
import Alert from '@cloudscape-design/components/alert';
import { getTextFilterCounterText } from '../../../utils/text-filter';
import { useLocalStorage } from '../../../components/use-local-storage';
import { useColumnWidths } from '../../../components/table/use-column-width';
import { FILTERING_PROPERTIES as filteringProperties } from './filtering_poperties';
import { mutate } from 'swr';

import { Preferences, useTableConfig } from '../hooks/useTableConfig';

import fetcher from '../../../utils/fetcher';

import { TableEmptyStateProps } from '../../../components/table/commons';
import { User, UsersArray, UserStatuses } from '../types';
import { useNavigate } from 'react-router-dom';
import { useApiWithFlash } from '../../../utils/hooks/useApiWithFlash';
import { ResourceTypes, RoleRecordsList } from '../../../types/rolePermissions';
import { NonCancelableEventHandler } from '@cloudscape-design/components/internal/events';
import UserSplitPanelDetails from './userSplitPanelDetails';
import { useSplitPanelControl } from '../../../utils/hooks/splitPanelContext';
import { useCatoCollection } from '../../../utils/hooks/useCatoCollection';
import UserRolesModal from '../modify-roles';

export interface UserTableProps {
  isLoading: boolean;
  users: UsersArray;
  roles?: RoleRecordsList;
  error: boolean;
  currentUser: string;
}

export default function UserTable({ isLoading, users = [], roles = [], currentUser, error }: Readonly<UserTableProps>) {
  // State
  const { columns, defaultPreferences } = useTableConfig({ roles });
  const [refreshLoading, setRefreshLoading] = useState(false);
  const [selectedItem, setSelectedItem] = useState<User>();
  const [actionLoading, setActionLoading] = useState(false);
  const [columnDefinitions, saveWidths] = useColumnWidths('Cato-Users-TableEditable-Widths', columns);
  const [preferences, setPreferences] = useLocalStorage('Cato-Users-TableEditable-Preferences', defaultPreferences);
  const [isModalVisible, setIsModalVisible] = useState(false);

  // Hooks
  const navigate = useNavigate();
  const { handleApiWithFlash } = useApiWithFlash();
  const { setSplitPanelOpen, setSplitPanelContent } = useSplitPanelControl();

  useEffect(() => {
    setSplitPanelContent(
      <>
        {selectedItem && (
          <UserSplitPanelDetails
            selectedItem={selectedItem}
            rolesData={roles}
          />
        )}
      </>,
    );
    setSplitPanelOpen(!!selectedItem);
  }, [selectedItem]);

  const tableEmptyStateProps: TableEmptyStateProps = {
    resourceName: 'Users',
    resourceType: ResourceTypes.Admin,
    urlPath: 'user-admin',
  };

  const { items, filteredItemsCount, collectionProps, propertyFilterProps, paginationProps } = useCatoCollection(users, {
    filteringProperties,
    tableEmptyStateProps,
    pageSize: preferences.pageSize,
  });

  const removeUser = async () => {
    const username = selectedItem?.Username;
    const isExternallyManagedUser = selectedItem?.UserStatus === UserStatuses.ExternalProvider;

    await handleApiWithFlash(`/users/${username}`, 'DELETE', {
      successMessage: `Successfully deleted user: ${username}`,
      errorMessage: `Error deleting user: ${username}`,
      mutateKey: '/users',
      deleteMessage: (
        <>
          <p>
            Are you sure you want to delete user: <b>{username}</b>{' '}
          </p>
          {isExternallyManagedUser && (
            <Alert
              statusIconAriaLabel='Warning'
              type='warning'
            >
              This is an externally managed user, access must be managed from the IdP. Removing the user fom Cato will not remove the users access.
            </Alert>
          )}
        </>
      ),
    });
    // Clear down the selected user to clear the button
    setSelectedItem(undefined);
  };

  const onRefresh = async () => {
    setRefreshLoading(true);
    await mutate('/users');
    setRefreshLoading(false);
  };

  const toggleActiveUser = async () => {
    setActionLoading(true);
    await fetcher(`/users/${selectedItem?.Username}/status`, 'PATCH');
    await onRefresh();
    setSelectedItem(undefined);
    setActionLoading(false);
  };

  // This is used to handle button presses
  const handleUtilityItemClick = async (event: CustomEvent<ButtonDropdownProps.ItemClickDetails>) => {
    const { detail } = event;
    if (detail.id === 'remove') {
      removeUser();
    }
    if (detail.id === 'toggle-active') {
      toggleActiveUser();
    }
    if (detail.id === 'modify-roles') {
      setIsModalVisible(true);
    }
  };

  const closeModal = () => {
    setIsModalVisible(false);
    setSelectedItem(undefined);
    onRefresh();
  };

  const refreshButtonProps = { onClick: onRefresh };

  // This is used to handle inline edits within with table
  const submitEdit = async (currentItem: User, column: any, value: any) => {
    if (column.id === 'securityProfile') {
      const data = {
        securityProfile: value,
      };
      try {
        await fetcher(`/users/${currentItem.Username}/profile`, 'PATCH', data);
        await onRefresh();
      } catch (error) {
        console.error('Failed to update security profile:', error);
        throw error; // This will cause the promise to reject and the UI to show an error
      }
    }
  };

  const onSelectionChange: NonCancelableEventHandler<TableProps.SelectionChangeDetail<User>> = async (event) => {
    const { selectedItems } = event.detail;
    if (selectedItems && selectedItems.length > 0) {
      const [tmpSelectedItem] = selectedItems;
      setSelectedItem(() => tmpSelectedItem);
    }
  };

  const header = (
    <Header
      variant='awsui-h1-sticky'
      actions={
        <SpaceBetween
          direction='horizontal'
          size='xs'
        >
          <Button
            iconName='refresh'
            ariaLabel='Refresh'
            {...refreshButtonProps}
          />
          <ButtonDropdown
            disabled={!selectedItem}
            onItemClick={handleUtilityItemClick}
            loading={actionLoading}
            loadingText={'loading'}
            items={[
              {
                text: 'Toggle active',
                id: 'toggle-active',
                disabled: selectedItem?.UserStatus === UserStatuses.ExternalProvider,
              },
              {
                text: 'Remove',
                id: 'remove',
                disabled: selectedItem?.Username === currentUser,
              },
              {
                text: 'Modify Roles',
                id: 'modify-roles',
              },
            ]}
          >
            Actions
          </ButtonDropdown>
          <Button
            variant='primary'
            onClick={() => navigate('/user-admin/create')}
          >
            Create user
          </Button>
        </SpaceBetween>
      }
    >
      Application users {users && !isLoading ? `(${users.length})` : <Spinner />}
    </Header>
  );

  const tableProps: TableProps = {
    ...collectionProps,
    stickyHeader: true,
    resizableColumns: true,
    onColumnWidthsChange: saveWidths,
    onSelectionChange,
    selectedItems: selectedItem ? [selectedItem] : [],
    columnDefinitions,
    items,
    submitEdit,
    trackBy: 'Username',
    loadingText: 'Loading users',
    columnDisplay: preferences.contentDisplay,
    wrapLines: preferences.wrapLines,
    stripedRows: preferences.stripedRows,
    stickyColumns: preferences.stickyColumns,
    contentDensity: preferences.contentDensity as 'compact' | 'comfortable',
    selectionType: 'single',
    loading: isLoading || !users || refreshLoading,
    header,
    filter: (
      <PropertyFilter
        {...propertyFilterProps}
        countText={getTextFilterCounterText(filteredItemsCount)}
        expandToViewport={true}
        filteringAriaLabel={'Filter users'}
        filteringPlaceholder={'Filter users'}
      />
    ),
    pagination: <Pagination {...paginationProps} />,
    preferences: <Preferences {...{ preferences, setPreferences }} />,
  };

  return (
    <>
      <Table {...{ ...tableProps }} />
      {error && (
        <Alert
          statusIconAriaLabel='Error'
          type='error'
          header='Error getting data'
        >
          Refresh the page to try again.
        </Alert>
      )}
      {selectedItem && isModalVisible && (
        <Modal
          onDismiss={closeModal}
          visible={true}
          size='large'
          header={` ${selectedItem.Username}`}
        >
          <UserRolesModal
            roles={roles}
            user={selectedItem}
          />
        </Modal>
      )}
    </>
  );
}
