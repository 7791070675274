import { ColumnLayout, Container, Header, KeyValuePairs, SpaceBetween } from '@cloudscape-design/components';
import dayjs from 'dayjs';

export interface ContactQueueDisplayProps {
  queueName: string;
  queueDuration: number;
  callEnqueueAtTimestamp: string;
  callDequeueAtTimestamp: string;
}

function formatSeconds(totalSeconds: number): string {
  const hours = Math.floor(totalSeconds / 3600);
  const minutes = Math.floor((totalSeconds % 3600) / 60);
  const seconds = totalSeconds % 60;

  const formattedHours = hours.toString().padStart(2, '0');
  const formattedMinutes = minutes.toString().padStart(2, '0');
  const formattedSeconds = seconds.toString().padStart(2, '0');

  return `${formattedHours}:${formattedMinutes}:${formattedSeconds}`;
}

export default function ContactQueueDisplay(props: Readonly<ContactQueueDisplayProps>) {
  return (
    <Container header={<Header headingTagOverride='h3'>Queue</Header>}>
      <ColumnLayout
        columns={3}
        variant='text-grid'
      >
        <SpaceBetween size='xs'>
          <KeyValuePairs items={[{ label: 'Queue', value: props.queueName }]} />
          <KeyValuePairs items={[{ label: 'Queue Duration', value: formatSeconds(props.queueDuration) }]} />
        </SpaceBetween>
        <SpaceBetween size='xs'>
          <KeyValuePairs items={[{ label: 'Enqueued At', value: dayjs(props.callEnqueueAtTimestamp).format('YYYY-MM-DD HH:mm') }]} />
          <KeyValuePairs items={[{ label: 'Dequeued At', value: dayjs(props.callDequeueAtTimestamp).format('YYYY-MM-DD HH:mm') }]} />
        </SpaceBetween>
      </ColumnLayout>
    </Container>
  );
}
