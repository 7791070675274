import { ColumnLayout, Container, Header, KeyValuePairs, SpaceBetween } from '@cloudscape-design/components';
import { ContactRecord } from '../../../../types/contactRecord';
import dayjs from 'dayjs';
import { snakeToTitleCase } from '../../../../utils/strings';

interface ContactConnectionDisplayProps {
  contactObject: ContactRecord;
}

export function ContactConnectionDisplay(props: Readonly<ContactConnectionDisplayProps>) {
  const { contactObject } = props;
  return (
    <Container header={<Header headingTagOverride='h3'>Connections</Header>}>
      <ColumnLayout
        columns={3}
        variant='text-grid'
      >
        <SpaceBetween size='xs'>
          <KeyValuePairs items={[{ label: 'Connected To System', value: dayjs(contactObject.connectedToSystemTimestamp).format('YYYY-MM-DD HH:mm') }]} />
        </SpaceBetween>

        <SpaceBetween size='xs'>
          <KeyValuePairs items={[{ label: 'Customer Endpoint', value: contactObject.customerEndpointAddress }]} />
          <KeyValuePairs items={[{ label: 'Customer Endpoint Type', value: snakeToTitleCase(contactObject.customerEndpointType) }]} />
        </SpaceBetween>
        <SpaceBetween size='xs'>
          <KeyValuePairs items={[{ label: 'System Endpoint', value: contactObject.systemEndpointAddress }]} />
          {contactObject.systemEndpointType && (
            <KeyValuePairs items={[{ label: 'System Endpoint Type', value: snakeToTitleCase(contactObject.systemEndpointType) }]} />
          )}
        </SpaceBetween>
      </ColumnLayout>
    </Container>
  );
}
