import React, { createContext, useContext, ReactNode, useState } from "react";

interface ModalContextProps {
  children: ReactNode;
}

interface ModalContextValue {
  showModal: (content: React.ReactNode) => void;
  hideModal: () => void;
  isModalVisible: boolean;
  modalContent: React.ReactNode | null;
}

const ModalContext = createContext<ModalContextValue | null>(null);

export const ModalProvider = ({ children }: ModalContextProps) => {
  const [isModalVisible, setModalVisible] = useState(false);
  const [modalContent, setModalContent] = useState<React.ReactNode | null>(
    null,
  );

  const showModal = (content: React.ReactNode) => {
    setModalContent(content);
    setModalVisible(true);
  };

  const hideModal = () => {
    setModalVisible(false);
    setModalContent(null);
  };

  return (
    <ModalContext.Provider
      value={{ showModal, hideModal, isModalVisible, modalContent }}
    >
      {children}
      {isModalVisible && <div className="modal-overlay">{modalContent}</div>}
    </ModalContext.Provider>
  );
};

export const useModal = () => {
  const context = useContext(ModalContext);
  if (!context) {
    throw new Error("useModal must be used within a ModalProvider");
  }
  return context;
};
