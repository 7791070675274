import { useCallback } from 'react';
import { v4 as uuidv4 } from 'uuid';

export const useInlinePlannedEdit = (setItem: React.Dispatch<any>) => {
  return {
    handlePlannedEdit: useCallback(
      (field: string, targetItem: any, column: any, newValue: any) => {
        setItem((prevState: any) => {
          // Clone the existing item data to avoid direct mutations
          const updatedItem = JSON.parse(JSON.stringify(prevState));

          // Find the index of the target closure by its unique key
          const targetIndex = updatedItem.data.plannedClosures.findIndex((closure: any) => closure.key === targetItem.key);

          // If a matching closure is found, update its specified field
          if (targetIndex !== -1) {
            if (field === 'default') {
              // Handle the special case where the field is deeply nested
              const defaultMessageObject = updatedItem.data.plannedClosures[targetIndex].message.find((msg: any) => msg.lang === 'default');
              if (defaultMessageObject?.message?.[0]?.children?.[0]) {
                defaultMessageObject.message[0].children[0].text = newValue;
              }
            } else {
              // Handle other fields
              updatedItem.data.plannedClosures[targetIndex][field] = newValue;
            }
          }

          return updatedItem;
        });
      },
      [setItem],
    ),
    removePlannedRow: useCallback(
      (event: React.MouseEvent<SVGSVGElement, MouseEvent>, keyToRemove: any, type = 'closure') => {
        event.stopPropagation(); // To prevent any other unintended event triggers

        setItem((prevState: any) => {
          // Deep clone the existing data to avoid direct mutation
          const updatedData = JSON.parse(JSON.stringify(prevState.data));

          // Filter out the row with the keyToRemove from daysOfWeek
          if (type === 'closure') {
            updatedData.plannedClosures = updatedData.plannedClosures.filter((closure: any) => closure.key !== keyToRemove);
          }

          return {
            ...prevState,
            data: updatedData,
          };
        });
      },
      [setItem],
    ),

    addPlannedRow: useCallback(
      (type = 'closure') => {
        setItem((prevState: any) => {
          // Deep clone the existing data to avoid direct mutation
          const updatedData = JSON.parse(JSON.stringify(prevState.data));

          if (type === 'plannedClosure') {
            const newRow = {
              date: '',
              description: 'New event',
              endTime: '',
              recurrence: 'none',
              key: uuidv4(),
              message: [
                {
                  lang: 'default',
                  message: [
                    {
                      children: [
                        {
                          text: 'Closure message',
                        },
                      ],
                      type: 'paragraph',
                    },
                  ],
                },
              ],
              startTime: '',
            };
            updatedData.plannedClosures.push(newRow);
          }

          return {
            ...prevState,
            data: updatedData,
          };
        });
      },
      [setItem],
    ),
  };
};
