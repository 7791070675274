export const FILTERING_PROPERTIES = [
    {
        propertyLabel: 'Username',
        key: 'currentAgentSnapshotConfigurationUsername',
        groupValuesLabel: 'Username',
        operators: [':', '!:', '=', '!='] as const,
    },
    {
        propertyLabel: 'Event Type',
        key: 'eventType',
        groupValuesLabel: 'Event Type',
        operators: [':', '!:', '=', '!='] as const,
    },
    {
        propertyLabel: 'AutoAccept',
        key: 'currentAgentSnapshotConfigurationAutoAccept',
        groupValuesLabel: 'AutoAccept',
        operators: [':', '!:', '=', '!='] as const,
    },
    {
        propertyLabel: 'Status',
        key: 'currentAgentSnapshotAgentStatusName',
        groupValuesLabel: 'Status',
        operators: [':', '!:', '=', '!='] as const,
    },
    {
        propertyLabel: 'Contact State',
        key: 'currentAgentSnapshotFirstContactState',
        groupValuesLabel: 'Contact State',
        operators: [':', '!:', '=', '!='] as const,
    },
    {
        propertyLabel: 'Next Agent State',
        key: 'currentAgentSnapshotNextAgentStatus',
        groupValuesLabel: 'Next Agent State',
        operators: [':', '!:', '=', '!='] as const,
    },
    {
        propertyLabel: 'Contact Queue Name',
        key: 'currentAgentSnapshotFirstContactQueueName',
        groupValuesLabel: 'Contact Queue Name',
        operators: [':', '!:', '=', '!='] as const,
    },
    {
        propertyLabel: 'Routing Profile',
        key: 'currentAgentSnapshotConfigurationRoutingProfileName',
        groupValuesLabel: 'Routing Profile',
        operators: [':', '!:', '=', '!='] as const,
    },
    {
        propertyLabel: 'Hierarchy L1',
        key: 'currentAgentSnapshotConfigurationAgentHierarchyGroupsL1',
        groupValuesLabel: 'Hierarchy L1',
        operators: [':', '!:', '=', '!='] as const,
    },
    {
        propertyLabel: 'Hierarchy L2',
        key: 'currentAgentSnapshotConfigurationAgentHierarchyGroupsL2',
        groupValuesLabel: 'Hierarchy L2',
        operators: [':', '!:', '=', '!='] as const,
    },
    {
        propertyLabel: 'Hierarchy L3',
        key: 'currentAgentSnapshotConfigurationAgentHierarchyGroupsL3',
        groupValuesLabel: 'Hierarchy L3',
        operators: [':', '!:', '=', '!='] as const,
    },
    {
        propertyLabel: 'Hierarchy L4',
        key: 'currentAgentSnapshotConfigurationAgentHierarchyGroupsL4',
        groupValuesLabel: 'Hierarchy L4',
        operators: [':', '!:', '=', '!='] as const,
    },
    {
        propertyLabel: 'Hierarchy L5',
        key: 'currentAgentSnapshotConfigurationAgentHierarchyGroupsL5',
        groupValuesLabel: 'Hierarchy L5',
        operators: [':', '!:', '=', '!='] as const,
    },
] as const;

export const filteringFunction2: (
    item: { currentAgentSnapshot: { Configuration: { Username: string; }; }; },
    filteringText: string,
    filteringFields?: string[] | undefined
) => boolean = (item, filteringText, filteringFields) => {
    // Implement custom filtering logic here
    console.log("filteringFunction", item, filteringText, filteringFields)
    if (filteringFields && filteringFields.includes('username')) {
        const username = item.currentAgentSnapshot.Configuration.Username || '';
        return username.toLowerCase().includes(filteringText.toLowerCase());
    }

    // Implement the filtering logic for other fields

    return true;  // return true if the item passes the filter, false otherwise
};

export const filteringFunction3 = (item: any, filteringText: string, filteringFields?: string[] | undefined) => {
    console.log("filteringFunction", item, filteringText, filteringFields);
    return true
};

export const filteringFunction4 = (item: any, query: any) => {
    console.log("filteringFunction", item, query);
    return true
};


// Helper function to get nested properties
const getNestedProperty = (obj: any, path: string): any => {
    return path.split('.').reduce((acc, part) => acc && acc[part], obj);
};

export const filteringFunction = (
    item: any,
    query: any
): boolean => {
    console.log("filters", item, query)
    const conditions: boolean[] = query.tokens.map((token: { propertyKey: string; operator: any; value: any; }) => {
        const propertyValue = getNestedProperty(item, token.propertyKey);

        switch (token.operator) {
            case "=":
                return propertyValue === token.value;
            case "!=":
                return propertyValue !== token.value;
            case ":":  // includes
                if (typeof propertyValue === "string") {
                    return propertyValue.includes(token.value);
                } else if (Array.isArray(propertyValue)) {
                    return propertyValue.includes(token.value);
                } else {
                    return false;
                }
            case "!:":  // !includes
                if (typeof propertyValue === "string") {
                    return !propertyValue.includes(token.value);
                } else if (Array.isArray(propertyValue)) {
                    return !propertyValue.includes(token.value);
                } else {
                    return false;
                }
            default:
                return false;
        }
    });

    if (query.operation === "and") {
        return conditions.every(Boolean);
    }

    if (query.operation === "or") {
        return conditions.some(Boolean);
    }

    return false;
};


