import React, {ReactElement} from "react";

const PoweredBy: React.FC = (): ReactElement => (
    <div className="footer-right">
        Powered by{' '}
        <a href="https://www.cx.studio" target="_blank" rel="noopener noreferrer"
           style={{textDecoration: 'underline', color: 'inherit'}}>
            <b>CX Studio</b>
        </a>
    </div>
);

export default PoweredBy;
