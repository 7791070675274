import { useNavigate, useParams } from 'react-router-dom';
import EditSurveyForm from '../form';
import { useApiWithFlash } from '../../../utils/hooks/useApiWithFlashInstance';
import useInstanceSWR from '../../../utils/hooks/useInstanceSWR';
import { SurveyData } from '../types';
import { Alert, Spinner } from '@cloudscape-design/components';

export default function SurveyEdit() {
  const navigate = useNavigate();
  const { featureId } = useParams();
  const { handleApiWithFlash } = useApiWithFlash();

  const { data, isLoading, isValidating, error } = useInstanceSWR<SurveyData>(`/surveys/${featureId}`, {
    revalidateOnFocus: false,
    revalidateOnReconnect: false,
    refreshInterval: 0,
  });

  if (error) {
    return (
      <Alert
        statusIconAriaLabel='Error'
        type='error'
        header='Unable to load component'
      >
        {error.status}
      </Alert>
    );
  }

  if (isLoading || isValidating || !data?.data) {
    return <Spinner />;
  }

  const onSubmitForm = async (updateSurvey: SurveyData) => {
    const { feature, ...surveyData } = updateSurvey;
    const response = await handleApiWithFlash(`/surveys/${feature}`, 'PUT', {
      successMessage: `Successfully updated Survey group ${feature}`,
      errorMessage: `Error updating Survey group ${feature}`,
      data: surveyData,
      mutateKey: `/surveys/${featureId}`,
    });

    if (response?.success) {
      navigate('/surveys');
    }
  };

  const survey = data.data;

  return <EditSurveyForm {...{ onSubmitForm, survey }} />;
}
