import React from 'react';
import useSWR from 'swr';
import fetcher from '../../utils/fetcher'
import Header from '@cloudscape-design/components/header';
import SpaceBetween from '@cloudscape-design/components/space-between';
import {ContentLayout} from '@cloudscape-design/components';
import UserTable from "./components/table";

export default function PromptEngineering() {
    const {data, isLoading, error} = useSWR('/prompt-dictionary', fetcher, {
        revalidateOnFocus: false,
        revalidateOnReconnect: false,
        refreshInterval: 0,
    });

    return (
        <ContentLayout
            header={
                <Header
                    variant="h1"
                    description="Manage application promots that drive the Generative AI chatbot responses"
                >
                    Manage prompt configuration
                </Header>
            }
        >
            <SpaceBetween size="m">
                <UserTable isLoading={isLoading} error={error} prompts={data?.data}/>
            </SpaceBetween>
        </ContentLayout>
    );
}

