import * as React from "react";
import {Textarea, FormField} from "@cloudscape-design/components";
import {useContext} from "react";
import {ChatWebSocketContext, ChatWebSocketContextType} from "./contexts/chatWebSocketContext";
import {BaseChangeDetail} from "@cloudscape-design/components/input/interfaces";

export default function PromptIntro() {
    // Hooks & Contexts
    const {promptIntro, setPromptIntro} = useContext<ChatWebSocketContextType>(ChatWebSocketContext);

    // Functions
    function handlePromptOptionsChange(detail: BaseChangeDetail) {
        setPromptIntro(detail.value);
    }

    // Render
    return (
        <FormField
            description="Enter the text below to prompt the bot with additional informaiton about the requester, this is used to shape the repsonses."
            label="Prompt intro"
        >
            <Textarea
                value={promptIntro}
                onChange={({detail}) => handlePromptOptionsChange(detail)}
            />
        </FormField>
    );
}
