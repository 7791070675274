import { ColumnLayout, Container, Header, KeyValuePairs, SpaceBetween } from '@cloudscape-design/components';
import { ContactAttributes } from '../../../../types/contactRecord';

export interface ContactAttributeProps {
  contactAttributes: ContactAttributes;
}

type AttributeList = { label: string; value: string }[];

export interface SortedAttributes {
  other: AttributeList;
  catoSystem: AttributeList;
}

function sortAttributes(attributesList: ContactAttributes): SortedAttributes {
  const other: AttributeList = [];
  const catoSystem: { label: string; value: string }[] = [];
  for (const key in attributesList) {
    if (key.startsWith('cx')) {
      catoSystem.push({ label: key, value: attributesList[key] });
    } else {
      other.push({ label: key, value: attributesList[key] });
    }
  }
  return { other, catoSystem };
}
function createSplitList(list: AttributeList) {
  const listCeil = Math.ceil(list.length / 3);
  return [list.slice(0, listCeil), list.slice(listCeil, listCeil * 2), list.slice(listCeil * 2)];
}

export default function ContactAttributesDisplay(props: Readonly<ContactAttributeProps>) {
  const { other, catoSystem } = sortAttributes(props.contactAttributes);

  const splitOtherList = createSplitList(other);
  const splitCatoSystemList = createSplitList(catoSystem);
  return (
    <Container header={<Header headingTagOverride='h3'>Attributes</Header>}>
      <ColumnLayout
        columns={3}
        variant='text-grid'
      >
        {/* <KeyValuePairs items={items} columns={3 } /> */}
        <SpaceBetween size='xs'>
          {splitOtherList[0].map((record) => (
            <KeyValuePairs
              key={record.label}
              items={[record]}
            />
          ))}
        </SpaceBetween>
        <SpaceBetween size='xs'>
          {splitOtherList[1].map((record) => (
            <KeyValuePairs
              key={record.label}
              items={[record]}
            />
          ))}
        </SpaceBetween>
        <SpaceBetween size='xs'>
          {splitOtherList[2].map((record) => (
            <KeyValuePairs
              key={record.label}
              items={[record]}
            />
          ))}
        </SpaceBetween>
      </ColumnLayout>
      <ColumnLayout
        columns={3}
        variant='text-grid'
      >
        <SpaceBetween size='xs'>
          {splitCatoSystemList[0].map((record) => (
            <KeyValuePairs
              key={record.label}
              items={[record]}
            />
          ))}
        </SpaceBetween>
        <SpaceBetween size='xs'>
          {splitCatoSystemList[1].map((record) => (
            <KeyValuePairs
              key={record.label}
              items={[record]}
            />
          ))}
        </SpaceBetween>
        <SpaceBetween size='xs'>
          {splitCatoSystemList[2].map((record) => (
            <KeyValuePairs
              key={record.label}
              items={[record]}
            />
          ))}
        </SpaceBetween>
      </ColumnLayout>
    </Container>
  );
}
