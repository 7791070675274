import React, { ReactNode } from 'react';
import { Box } from '@cloudscape-design/components';

export interface ValueWithLabelProps {
  label: string;
  children: ReactNode;
  key?: string;
}

export const ValueWithLabel: React.FC<ValueWithLabelProps> = ({ label, children, key }) => (
  <div key={key}>
    <Box variant='awsui-key-label'>{label}</Box>
    <div>{children}</div>
  </div>
);
