const queryParams = new URLSearchParams(window.location.search);
const isIframe = queryParams.get('iframe') === 'true';

const customTheme = {
  tokens: {
    fontFamilyBase: "'Ubuntu', sans-serif",
    // colorBackgroundContainerContent: '#007dfa', // Container background
    borderRadiusContainer: isIframe ? '0px' : '16px', // Container radius
  },
  contexts: {
    'top-navigation': {
      tokens: {
        colorTextTopNavigationTitle: '#d0cece', // Logo text (top left of app bar)
        colorBackgroundContainerContent: '#091540', // AppBar background
      },
    },
    header: {
      tokens: {
        colorBackgroundLayoutMain: '#091540', // Page top section
        colorTextBreadcrumbCurrent: '#fff', // Breadcrumbs, current page
        colorTextLinkDefault: '#fff', // Breadcrumbs, path to current
        colorTextHeadingDefault: '#fff', // Page titles
      },
    },
  },
};

export default customTheme;
