import React from 'react';
import useSWR from 'swr';
import fetcher from '../../utils/fetcher';
import Header from '@cloudscape-design/components/header';
import SpaceBetween from '@cloudscape-design/components/space-between';
import { ContentLayout, Container, Alert, Button } from '@cloudscape-design/components';
import DocumentTable from './components/table';
import { useNavigate } from 'react-router-dom';

export default function UserAdmin() {
  const navigate = useNavigate();
  const { data: promptData, isLoading: promptLoading } = useSWR('/prompt-dictionary', fetcher, {
    revalidateOnFocus: false,
    revalidateOnReconnect: false,
    refreshInterval: 0,
  });

  const urlToFetch = promptData && promptData?.data.length > 0 ? '/documents' : null;

  const {
    data: documentsData,
    isLoading: documentsLoading,
    error: documentsError,
  } = useSWR(urlToFetch, fetcher, {
    revalidateOnFocus: false,
    revalidateOnReconnect: false,
    refreshInterval: 0,
  });

  const displayWarning = !promptLoading && (!promptData || promptData?.data.length === 0);

  return (
    <ContentLayout
      header={
        <Header variant="h1" description="Manage documents that are used within the Gen AI chatbot">
          Documents
        </Header>
      }
    >
      <SpaceBetween size="m">
        {displayWarning ? (
          <Container header={<Header variant="h2">Documents</Header>}>
            <Alert
              statusIconAriaLabel="Warning"
              type="warning"
              action={<Button onClick={() => navigate('/prompt-engineering/create')}>Create a prompt</Button>}
            >
              Please create a prompt before uploading any documents
            </Alert>
          </Container>
        ) : (
          <DocumentTable isLoading={documentsLoading} error={documentsError} documents={documentsData?.data} />
        )}
      </SpaceBetween>
    </ContentLayout>
  );
}
