export const FILTERING_PROPERTIES = [
    {
        propertyLabel: 'Visualization Dashboard',
        key: 'feature',
        groupValuesLabel: 'Visualization values',
        operators: [':', '!:', '=', '!='] as const,
    },
    {
        propertyLabel: 'Description',
        key: 'description',
        groupValuesLabel: 'Description',
        operators: [':', '!:', '=', '!='] as const,
    },
] as const;
