export type PromptItem = {
  configType: string;
  version: string;
  type: string;
  updatedBy: string;
  updatedAt: string;
  createdAt: string;
  data: {
    embeddingSearchStatus: boolean;
    dynamicPromptCompletionTokenLimit: number;
    promptName: string;
    genAIModel: string;
  };
};

export const FILTERING_PROPERTIES = [
  {
    propertyLabel: 'Name',
    key: 'feature',
    groupValuesLabel: 'Name values',
    operators: [':', '!:', '=', '!='] as const,
  },
] as const;
