export const FILTERING_PROPERTIES = [
    {
        propertyLabel: 'ACW',
        key: 'average_agentAfterContactWorkDuration',
        groupValuesLabel: 'ACW',
        operators: [':', '!:', '=', '!=', '>', '<'] as const,
    },
    {
        propertyLabel: 'AHT',
        key: 'average_talk_time',
        groupValuesLabel: 'AHT',
        operators: [':', '!:', '=', '!=', '>', '<'] as const,
    }, {
        propertyLabel: 'Hold Count',
        key: 'agentNumberOfHoldsCount',
        groupValuesLabel: 'Hold number',
        operators: [':', '!:', '=', '!=', '>', '<'] as const,
    }, {
        propertyLabel: 'Handled',
        key: 'no_of_contactIds',
        groupValuesLabel: 'Handled',
        operators: [':', '!:', '=', '!=', '>', '<'] as const,
    }, {
        propertyLabel: 'Hold Duration',
        key: 'agentCustomerHoldDurationTotal',
        groupValuesLabel: 'Hold duration',
        operators: [':', '!:', '=', '!=', '>', '<'] as const,
    }, {
        propertyLabel: 'Sentiment Customer',
        key: 'average_customer_sentiment',
        groupValuesLabel: 'Customer Sentiment',
        operators: [':', '!:', '=', '!=', '>', '<'] as const,
    }, {
        propertyLabel: 'Sentiment Agent',
        key: 'average_agent_sentiment',
        groupValuesLabel: 'Agent Sentiment',
        operators: [':', '!:', '=', '!=', '>', '<'] as const,
    },
    {
        propertyLabel: 'Username',
        key: 'username',
        groupValuesLabel: 'Username values',
        operators: [':', '!:', '=', '!='] as const,
    },
    {
        propertyLabel: '% transferred',
        key: 'percent_transferred',
        groupValuesLabel: '% transferred',
        operators: [':', '!:', '=', '!=', '>', '<'] as const,
    },
] as const;
