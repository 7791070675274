export const FILTERING_PROPERTIES = [
    {
        propertyLabel: 'Message Group',
        key: 'feature',
        groupValuesLabel: 'Message Group values',
        operators: [':', '!:', '=', '!='] as const,
    },
    {
        propertyLabel: 'Description',
        key: 'description',
        groupValuesLabel: 'Description',
        operators: [':', '!:', '=', '!='] as const,
    },
] as const;
