import { useNavigate } from 'react-router-dom';
import { useApiWithFlash } from '../../../utils/hooks/useApiWithFlashInstance';
import { EditVisualizationForm, PartialDashboardData } from '../form';

export default function VisualizationsCreate() {
  const navigate = useNavigate();
  const { handleApiWithFlash } = useApiWithFlash();

  async function handleSubmitForm(dashboard: PartialDashboardData) {
    delete dashboard.feature;
    const { title } = dashboard;
    const response = await handleApiWithFlash(`/intuition/dashboards`, 'POST', {
      successMessage: `Successfully created dashboard ${title}`,
      errorMessage: `Error creating dashboard ${title}`,
      mutateKey: `/intuition/dashboards`,
      data: dashboard,
    });

    if (response?.success) {
      navigate(`/visualizations/view/${response.data?.feature}`);
    } else {
      navigate(`/visualizations`);
    }
  }

  const formProps = {
    handleSubmitForm,
  };

  return <EditVisualizationForm {...formProps} />;
}
