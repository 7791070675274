import React from "react";
import Header from "@cloudscape-design/components/header";
import SpaceBetween from "@cloudscape-design/components/space-between";
import { Container, ContentLayout } from "@cloudscape-design/components";
import ReactMarkdown from "react-markdown";
import TextContent from "@cloudscape-design/components/text-content";

export default function Utilities() {
  return (
    <ContentLayout
      header={
        <Header
          variant="h1"
          description="Access additonal functions of the API provided by Cato"
        >
          Utilities
        </Header>
      }
    >
      <Container header={<Header headingTagOverride="h3">Utilities</Header>}>
        <SpaceBetween size="xl">
          <TextContent>
            <p>
              {" "}
              The following utilities are provided as part of CX Studio. This
              enables you to perform actions within the Contact Centre to
              improve your customer experiecne. utilities are accessed via the
              API that is provded to you by CX Studio. You can use these
              utilities in any contact flow where an API can be called.{" "}
            </p>
          </TextContent>
          <ReactMarkdown className="markdown-content">
            {`
    | Method      | Description                                                  | Usage                                   |
    | ----------- | ------------------------------------------------------------ | --------------------------------------- |
    | Random      | Returns a random number between 0 and 100                    | ---                                     |
    | LastChar    | Returns the last N characters                                | CX_N = 'N' & CX_Payload = 'string'      |
    | FirstChar   | Returns the first N characters                               | CX_N = 'N' & CX_Payload = 'string'      |
    | SubChar     | Returns the N characters, starting from P position           | CX_N = 'P,N' & CX_Payload = 'string'    |
    | Addition    | Adds N1 to N2 together                                       | CX_Payload = 'N1,N2'                    |
    | Subtraction | Subtracts N1 from N2                                         | CX_Payload = 'N1,N2'                    |
    
     ---> scroll
     `}
          </ReactMarkdown>
          <TextContent>
            <p>
              For example to get the last 4 characters of a string you will
              provide: Example Request:
            </p>
          </TextContent>
          <ReactMarkdown className="markdown-content">
            {`
\`\`\`json
{
  "Details": {...}, // Amazon Connect Lambda event
  "Parameters": {
      "CX_RequestType" : "LastChar", // The required method
      "CX_N" : "4", // The number of characters required back, for example 4
      "CX_Payload" : "string" // The string payload
  }
}
\`\`\`
     ---> scroll
    `}
          </ReactMarkdown>
          <TextContent>
            <p> Example Response:</p>
          </TextContent>
          <ReactMarkdown className="markdown-content">
            {`
\`\`\`json
{
   "CX_RequestType": "LastChar",
   "CX_Result": "ring"  // Our example was CX_N = 4, so the last 4 characters have been returned of the payload 'string'
}
\`\`\`
     ---> scroll
    `}
          </ReactMarkdown>
          <TextContent>
            <h3>Best practices</h3>
            <p>
              Below are some of the best practices when implementing
              MessageGroups.
            </p>
            <ul>
              <li>
                Have 1 MessageGroup per contact center script/flow. This will
                allow you to create reusable logic within the contact centre.
              </li>
              <li>
                Use PascalCase for the group name and throughout the application
              </li>
            </ul>
          </TextContent>
          <TextContent>
            <h3>Limits</h3>
            <p>Invocations count towards your capacity tier.</p>
          </TextContent>
        </SpaceBetween>
      </Container>
    </ContentLayout>
  );
}
