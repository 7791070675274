import React, {createContext, useContext, useState} from "react";

type Type = 'success' | 'error' | 'info';
type FlashMessage = {
    type: Type;
    content: string;
    action?: React.ReactNode;
    dismissible: boolean;
    dismissLabel: string;
    id: string;
    onDismiss?: () => void; // Add this line
};

const FlashContext = createContext<{
    flash: FlashMessage[];
    addFlash: (message: FlashMessage) => void;
    removeFlash: (id: string) => void;
} | undefined>(undefined);

interface FlashProviderProps {
    children: React.ReactNode;
}

export const FlashProvider: React.FC<FlashProviderProps> = ({children}) => {
    const [flash, setFlash] = useState<FlashMessage[]>([]);


    const removeFlash = (id: string) => {
        setFlash(prevFlash => prevFlash.filter(message => message.id !== id));
    };


    const addFlash = (message: FlashMessage) => {
        setFlash(prevFlash => [...prevFlash, message]);
    };

    return (
        <FlashContext.Provider value={{flash, addFlash, removeFlash}}>
            {children}
        </FlashContext.Provider>
    );
};

export const useFlash = () => {
    const context = useContext(FlashContext);
    if (!context) {
        throw new Error('useFlash must be used within a FlashProvider');
    }
    return context;
};
