export const FILTERING_PROPERTIES = [
  {
    propertyLabel: "Id",
    key: "id",
    groupValuesLabel: "Id values",
    operators: [":", "!:", "=", "!="] as const,
  },
  {
    propertyLabel: "File Name",
    key: "fileName",
    groupValuesLabel: "File Name",
    operators: [":", "!:", "=", "!="] as const,
  },
  {
    propertyLabel: "Status",
    key: "status",
    groupValuesLabel: "Status",
    operators: [":", "!:", "=", "!="] as const,
  },
  {
    propertyLabel: "Active",
    key: "active",
    groupValuesLabel: "Active",
    operators: [":", "!:", "=", "!="] as const,
  },
  {
    propertyLabel: "Created",
    key: "createdAt",
    groupValuesLabel: "Created",
    operators: [":", "!:", ">", "<"] as const,
  },
] as const;
