import React from 'react';
import { ColumnLayout, Container, Header, KeyValuePairs, SpaceBetween } from '@cloudscape-design/components';
import { ContactAttributes } from '../../../../types/contactRecord';

export interface ContactTagsDisplayProps {
  tags: ContactAttributes;
}

type AttributeList = { label: string; value: string }[];

function convertToArray(tags: ContactAttributes): AttributeList {
  const tagList: AttributeList = [];
  for (const key in tags) {
    tagList.push({ label: key, value: tags[key] });
  }
  return tagList;
}

function createSplitList(list: AttributeList) {
  const listCeil = Math.ceil(list.length / 3);
  return [list.slice(0, listCeil), list.slice(listCeil, listCeil * 2), list.slice(listCeil * 2)];
}
export default function ContactTagsDisplay(props: Readonly<ContactTagsDisplayProps>) {
  const tagList = convertToArray(props.tags);
  const splitTagList = createSplitList(tagList);
  return (
    <Container header={<Header headingTagOverride='h3'>Contact Tags</Header>}>
      <ColumnLayout
        columns={3}
        variant='text-grid'
      >
        <SpaceBetween size='xs'>
          {splitTagList[0].map((record) => (
            <KeyValuePairs
              key={record.label}
              items={[record]}
            />
          ))}
        </SpaceBetween>
        <SpaceBetween size='xs'>
          {splitTagList[1].map((record) => (
            <KeyValuePairs
              key={record.label}
              items={[record]}
            />
          ))}
        </SpaceBetween>
        <SpaceBetween size='xs'>
          {splitTagList[2].map((record) => (
            <KeyValuePairs
              key={record.label}
              items={[record]}
            />
          ))}
        </SpaceBetween>
      </ColumnLayout>
    </Container>
  );
}
