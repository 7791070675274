import { ContentLayout, Header, Container, SpaceBetween, ColumnLayout, FormField, Input, Textarea, Button, Form } from '@cloudscape-design/components';
import { DashboardDataRepresentation } from '../view/configurable-dashboard/components/widgets/interfaces';
import { useState } from 'react';
import useChangeDetector from '../../../utils/useChangeDetector';
import { TagIcon } from '../../utilities/tagIcon';
import { useBlockerLogic } from '../../../utils/hooks/useBlocker';
import { ConfirmCancelModal } from '../../../components/confirmCancelModal';

export type PartialDashboardData = Pick<DashboardDataRepresentation, 'title' | 'description' | 'tag'> & Pick<Partial<DashboardDataRepresentation>, 'feature'>;

export interface EditVisualizationFormProps {
  handleSubmitForm: (dashboard: PartialDashboardData) => Promise<void>;
  dashboard?: PartialDashboardData;
}

export const EditVisualizationForm = ({ handleSubmitForm, dashboard: initialDashboard }: EditVisualizationFormProps) => {
  const { title, feature, description, tag } = initialDashboard ?? { title: '', feature: '', description: '', tag: '' };
  const [formSubmitted, setFormSubmitted] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>();
  const { item: dashboard, setItem: setDashboard, changesDetected } = useChangeDetector<PartialDashboardData>({ title, feature, description, tag });
  const [tagError, setTagError] = useState<boolean>(false);
  const [tagErrorMessage, setTagErrorMessage] = useState<string>();

  const handleSubmit = () => {
    setIsLoading(true);
    setFormSubmitted(true);
    handleSubmitForm(dashboard);
  };

  const { blocker, handleCancel, handleCloseCancelModal, handleConfirmCancel } = useBlockerLogic({
    changesDetected,
    path: 'visualizations',
    formSubmitted,
  });

  const validateTag = (value: string) => {
    const generalPattern = /^[a-z0-9+_-]+$/;

    if (value.length > 0 && !generalPattern.test(value)) {
      setTagError(true);
      setTagErrorMessage('Only lowercase alphanumeric, - _ and + supported');
    } else {
      setTagError(false);
      setTagErrorMessage('');
    }
  };

  const handleTitleChange = (title: string) => {
    setDashboard({ ...dashboard, title });
  };

  const handleTagChange = (tag: string) => {
    setDashboard({ ...dashboard, tag });
    validateTag(tag);
  };

  const handleDescriptionChange = (description: string) => {
    setDashboard({ ...dashboard, description });
  };

  const isExistingDashboard = !!initialDashboard;

  const canSubmit = !tagError && dashboard.title && changesDetected;

  return (
    <ContentLayout header={<Header variant='h1'></Header>}>
      <Container
        header={
          <Header
            variant='h2'
            description={`Use this form to ${(isExistingDashboard && 'edit') || 'create'} a dashboard. You will add widgets on the next screen`}
          >
            {(isExistingDashboard && 'Edit') || 'Create'} a Dashboard
          </Header>
        }
      >
        <form
          onSubmit={(e) => {
            e.preventDefault();
            handleSubmit();
          }}
        >
          <Form
            variant='embedded'
            actions={
              <SpaceBetween
                direction='horizontal'
                size='xs'
              >
                <Button
                  formAction='none'
                  variant='link'
                  onClick={handleCancel}
                >
                  Cancel
                </Button>
                <Button
                  variant='primary'
                  loading={isLoading}
                  disabled={!canSubmit}
                >
                  Submit
                </Button>
              </SpaceBetween>
            }
          >
            <SpaceBetween
              direction='vertical'
              size='xl'
            >
              <ColumnLayout
                columns={2}
                variant='text-grid'
              >
                <FormField label={'Provide a dashboard name'}>
                  <Input
                    onChange={({ detail }) => handleTitleChange(detail.value)}
                    value={dashboard?.title ?? dashboard.feature as string}
                  />
                </FormField>
                <FormField
                  constraintText={'Only alphanumeric, - _ and + supported'}
                  errorText={tagErrorMessage}
                  label={
                    <>
                      Tag your dashboard <TagIcon />
                    </>
                  }
                >
                  <Input
                    onChange={({ detail }) => handleTagChange(detail.value)}
                    value={dashboard?.tag ?? ''}
                  />
                </FormField>
              </ColumnLayout>
              <FormField
                stretch
                label='Description'
              >
                <Textarea
                  onChange={({ detail }) => handleDescriptionChange(detail.value)}
                  value={dashboard?.description}
                />
              </FormField>
            </SpaceBetween>
          </Form>
        </form>
        {blocker.state === 'blocked' && (
          <ConfirmCancelModal
            {...{
              onCancel: handleCloseCancelModal,
              onConfirm: handleConfirmCancel,
              cancelMessage: 'Are you sure you want to close? Any unsaved changes will be lost.',
            }}
          />
        )}
      </Container>
    </ContentLayout>
  );
};
