import { useParams } from 'react-router-dom';
import useInstanceSWR from '../../../utils/hooks/useInstanceSWR';
import { SurveyData, SurveyResultsResponse } from '../types';
import {
  ColumnLayout,
  Container,
  ContentLayout,
  DateRangePicker,
  DateRangePickerProps,
  Header,
  PieChart,
  PieChartProps,
  Spinner,
} from '@cloudscape-design/components';
import { hasOwnProperty, objectKeys, objectValues } from '../../../utils/prototypeUtils';
import { objectToQueryString } from '../../../utils/queryParams';
import { useState } from 'react';
import moment from 'moment';

interface Range {
  start: number;
  end: number;
}
interface FormattedQuestionAnswer {
  question: string;
  values: string[];
  data: PieChartProps.PieChartData;
}
interface FormattedQuestionAnswersObject {
  [surveyName: string]: FormattedQuestionAnswer;
}
export const SurveyResults = () => {
  const { featureId } = useParams();
  const [range, setRange] = useState<Range>();
  const [rawDate, setRawDate] = useState<DateRangePickerProps.Value | null>(null);

  const {
    data: survey,
    isLoading: surveyIsLoading,
    error: surveyError,
  } = useInstanceSWR<SurveyData>(`/surveys/${featureId}`, {
    revalidateOnFocus: false,
    revalidateOnReconnect: false,
    refreshInterval: 0,
  });

  const getParams = objectToQueryString(range ?? {});
  const resultsUrl = `/surveys/${featureId}/results${getParams && '?'}${getParams}`;

  const { data, isLoading, error } = useInstanceSWR<SurveyResultsResponse>(resultsUrl, {
    revalidateOnFocus: false,
    revalidateOnReconnect: false,
    refreshInterval: 0,
  });

  const handleOnDateChange = (dateEvent: DateRangePickerProps.Value) => {
    setRawDate(dateEvent);
    if (dateEvent.type === 'absolute') {
      setRange({
        start: new Date(dateEvent.startDate).getTime(),
        end: new Date(dateEvent.endDate).getTime(),
      });
    }

    if (dateEvent.type === 'relative') {
      setRange({
        start: moment().subtract(dateEvent.amount, dateEvent.unit).valueOf(),
        end: Date.now(),
      });
    }
  };

  if (isLoading || surveyIsLoading) {
    return (
      <>
        <Spinner />
        Loading
      </>
    );
  }

  if (error || surveyError || !data || !survey) {
    return <>Error</>;
  }

  const questionsAndAnswers = survey.data.questions.reduce((response: FormattedQuestionAnswersObject, question, currentIndex) => {
    const formattedQuestAnswer: FormattedQuestionAnswer = { question, values: [], data: [] };

    return { ...response, [`survey_result_${currentIndex + 1}`]: formattedQuestAnswer };
  }, {});

  for (const resultRecord of data.data.Items) {
    for (const field of objectKeys(resultRecord)) {
      if (field.startsWith('survey_result_') && hasOwnProperty(questionsAndAnswers, field)) {
        const result = resultRecord[field];
        questionsAndAnswers[field].values.push(result);
      }
    }
  }

  objectValues(questionsAndAnswers).forEach((questionAndAnswer) => {
    const uniqueValues = [...new Set(questionAndAnswer.values)];
    questionAndAnswer.data = uniqueValues.map((title) => ({
      title,
      value: questionAndAnswer.values.reduce((count, value) => (value === title && count + 1) || count, 0),
    }));
  });

  const dateRangePickerProps: DateRangePickerProps = {
    onChange: ({ detail }) => detail.value && handleOnDateChange(detail.value),
    value: rawDate,
    relativeOptions: [
      {
        key: 'previous-1-week',
        amount: 1,
        unit: 'week',
        type: 'relative',
      },
      {
        key: 'previous-2-weeks',
        amount: 2,
        unit: 'week',
        type: 'relative',
      },
      {
        key: 'previous-1-month',
        amount: 1,
        unit: 'month',
        type: 'relative',
      },
      {
        key: 'previous-6-months',
        amount: 6,
        unit: 'month',
        type: 'relative',
      },
    ],
    isValidRange: (range) => {
      if (range?.type === 'absolute') {
        const [startDateWithoutTime] = range.startDate.split('T');
        const [endDateWithoutTime] = range.endDate.split('T');
        if (!startDateWithoutTime || !endDateWithoutTime) {
          return {
            valid: false,
            errorMessage: 'The selected date range is incomplete. Select a start and end date for the date range.',
          };
        }
        if (new Date(range?.startDate).getTime() - new Date(range?.endDate).getTime() > 0) {
          return {
            valid: false,
            errorMessage: 'The selected date range is invalid. The start date must be before the end date.',
          };
        }
      }
      return { valid: true };
    },
    i18nStrings: {},
    placeholder: 'Filter by a date and time range',
  };

  const pieChartProps: Omit<PieChartProps, 'data'> = {
    detailPopoverContent: (datum, sum) => [
      { key: 'Result count', value: datum.value },
      {
        key: 'Percentage',
        value: `${((datum.value / sum) * 100).toFixed(0)}%`,
      },
    ],
    segmentDescription: (datum, sum) => `${datum.value} votes, ${((datum.value / sum) * 100).toFixed(0)}%`,
    ariaDescription: 'Pie chart showing how many results have been submitted for each answer.',
    ariaLabel: 'Pie chart',
    hideFilter: true,
  };

  return (
    <ContentLayout
      header={
        <Header
          variant='h1'
          description={survey.data.description}
        >
          {survey.data.feature}
        </Header>
      }
    >
      <ColumnLayout
        columns={1}
        variant='default'
      >
        <DateRangePicker {...dateRangePickerProps} />
      </ColumnLayout>
      <ColumnLayout
        columns={2}
        variant='default'
      >
        {Object.values(questionsAndAnswers).map(({ question, values, data }) => (
          <Container
            header={
              <Header
                variant='h2'
                description={`${values.length} results`}
              >
                {question}
              </Header>
            }
            key={question}
          >
            <PieChart {...{ ...pieChartProps, data }} />
          </Container>
        ))}
      </ColumnLayout>
    </ContentLayout>
  );
};
