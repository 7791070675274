import React, { useEffect, useState } from 'react';
import { ColumnLayout, Container, SpaceBetween, SplitPanel } from '@cloudscape-design/components';
import { ValueWithLabel } from '../../../components/valueWithLabel';
import { getAttributeValue, userRoleStringToRoles } from '../utils/helpers';
import { RoleRecordsList } from '../../../types/rolePermissions';
import { User } from '../types';

interface FormattedUser {
  username: string;
  fullName: string;
  createDate: string;
  modifiedDate: string;
  securityProfile: string;
  userStatus: string;
  roles: RoleRecordsList;
}

interface AuditSplitPanelDetailsProps {
  selectedItem: User;
  rolesData: RoleRecordsList;
}

const UserSplitPanelDetails: React.FC<AuditSplitPanelDetailsProps> = ({ selectedItem, rolesData }) => {
  const [formattedUser, setFormattedUser] = useState<FormattedUser | null>(null);
  useEffect(() => {
    const createDate = parseDate(selectedItem.UserCreateDate);
    const modifiedDate = parseDate(selectedItem.UserLastModifiedDate);
    const fullName = `${parseAttributes(selectedItem, 'given_name')} ${parseAttributes(selectedItem, 'family_name')}`;

    const securityProfile = parseAttributes(selectedItem, 'custom:securityProfile');
    const roles = parseRoles(selectedItem);

    setFormattedUser({
      username: selectedItem.Username,
      fullName,
      createDate,
      modifiedDate,
      securityProfile,
      userStatus: selectedItem.UserStatus,
      roles,
    });
  }, [selectedItem, rolesData]);

  function parseAttributes(selectedItem: User, toFetch: string) {
    const value = getAttributeValue(selectedItem.Attributes, toFetch) || '';
    return value;
  }

  function parseDate(date: string) {
    if (!date) return '';
    return new Date(date).toLocaleString('en-GB', {
      hour12: false,
      minute: 'numeric',
      hour: 'numeric',
      day: '2-digit',
      month: '2-digit',
      year: 'numeric',
    });
  }

  function parseRoles(selectedItem: User) {
    const roleValue = parseAttributes(selectedItem, 'custom:roles');

    return userRoleStringToRoles(roleValue, rolesData);
  }

  return (
    formattedUser && (
      <SplitPanel
        header={`User: ${formattedUser.username}`}
        closeBehavior='collapse'
      >
        <Container>
          <ColumnLayout
            columns={2}
            variant='text-grid'
          >
            <SpaceBetween size='l'>
              <ValueWithLabel label='Username'>{formattedUser.username}</ValueWithLabel>
              <ValueWithLabel label='Full Name'>{formattedUser.fullName}</ValueWithLabel>
              <ValueWithLabel label='Created Date'>{formattedUser.createDate}</ValueWithLabel>
              <ValueWithLabel label='Modified Date'>{formattedUser.modifiedDate}</ValueWithLabel>
            </SpaceBetween>
            <SpaceBetween size='l'>
              <ValueWithLabel label='Security Profile'>{formattedUser.securityProfile}</ValueWithLabel>
              <ValueWithLabel label='User status'>{formattedUser.userStatus}</ValueWithLabel>
              {formattedUser.roles.length > 0 && (
                <ValueWithLabel label='Roles'>
                  <ul>
                    {formattedUser.roles.map(({name, feature}) => (
                      <li key={feature}>{name}</li>
                    ))}
                  </ul>
                </ValueWithLabel>
              )}
            </SpaceBetween>
          </ColumnLayout>
        </Container>
      </SplitPanel>
    )
  );
};

export default UserSplitPanelDetails;
