import moment from 'moment-timezone';
import { PlannedClosure } from '../types/closures';

export function isDateExpired(item: PlannedClosure, timezone: string): boolean {
  try {
    const dateToUse = item.recurrence !== 'none' ? item.recurrenceEnd : item.date;

    const itemDateTimeStr = `${dateToUse}T${item.endTime}:00`;
    const itemDateTime = moment.tz(itemDateTimeStr, timezone);

    const currentDateTimeInTimezone = moment.tz(timezone);

    return itemDateTime.isBefore(currentDateTimeInTimezone);
  } catch (error) {
    console.error('Error checking if date is expired:', error);
    return false;
  }
}
