import React from 'react';
import useInstanceSWR from '../../../utils/hooks/useInstanceSWR';
import { Box, SpaceBetween, SplitPanel, Tabs, ColumnLayout, KeyValuePairs, Table, Spinner } from '@cloudscape-design/components';
import { SurveyGroupListItem } from './table';
import Container from '@cloudscape-design/components/container';
import { SurveyData } from '../types';

const SurveysSplitPanelDetails: React.FC<{ selectedItem: SurveyGroupListItem }> = ({ selectedItem }) => {
  const selectedFeature = selectedItem.feature ?? 'N/A';
  const selectedTitle = selectedItem.feature ?? 'N/A';
  const selectedDescription = selectedItem.description ?? 'N/A';

  const { data, isLoading, error } = useInstanceSWR<SurveyData>(`/surveys/${selectedFeature}`, {
    revalidateOnFocus: false,
    revalidateOnReconnect: false,
    refreshInterval: 0,
  });

  if (isLoading || !data?.data || error) {
    return (
      <SplitPanel
        header={`Survey Group:`}
        closeBehavior='collapse'
      >
        {error ? <>{error.info.message}</> : <Spinner />}
      </SplitPanel>
    );
  }

  const { introPrompt, outroPrompt, questions } = data.data;

  const tab1 = (
    <Container>
      <ColumnLayout columns={1}>
        <KeyValuePairs
          items={[
            { label: 'Intro Prompt', value: introPrompt || 'N/A' },
            { label: 'Outro Prompt', value: outroPrompt || 'N/A' },
          ]}
        />
        <Table
          columnDefinitions={[
            {
              id: 'question',
              header: 'Questions',
              cell: (item: { question: string }) => item.question,
            },
          ]}
          items={questions.map((question: string, index: number) => ({
            id: `${selectedFeature}-${index}`,
            question,
          }))}
          variant='borderless'
        />
      </ColumnLayout>
    </Container>
  );

  return (
    <SplitPanel
      header={`Survey Group: ${selectedTitle}`}
      closeBehavior='collapse'
    >
      <SpaceBetween size='l'>
        <Box>{selectedDescription}</Box>
        <Tabs
          tabs={[
            {
              label: 'Survey Details',
              id: 'first',
              content: tab1,
            },
          ]}
        />
      </SpaceBetween>
    </SplitPanel>
  );
};

export default SurveysSplitPanelDetails;
