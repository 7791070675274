/* eslint-disable import/prefer-default-export */

const dayjs = require('dayjs');
require('dayjs/plugin/utc');
require('dayjs/plugin/timezone');

dayjs.extend(require('dayjs/plugin/utc'));
dayjs.extend(require('dayjs/plugin/timezone'));

export function getCurrentDate() {
  return dayjs().format();
}

export function epochToUTCString(epoch) {
  if (typeof epoch === 'string' && epoch.includes('#')) {
    epoch = epoch.split('#')[0];
  }

  const date = new Date(Number(epoch)); // convert EPOCH to Date object
  return date.toISOString(); // convert to ISO string in UTC
}

export function getTodayDate() {
  const today = dayjs().startOf('day').format();
  return encodeURIComponent(today);
}

export function getEpochTimeMinusHours(hours) {
  return Math.floor((Date.now() - hours * 60 * 60 * 1000) / 1000);
}

// This is used by components that need to query the database using start and end times. The component will get
// CxDateTimePicker that supports relative and absolute time ranges but needs to be formatted before making the API
// request
export function formatDateTimePickerApi(period) {
  if (period?.key === 'day' || period?.key === 'week' || period?.key === 'month' || period?.key === 'year') {
    const start = dayjs().startOf(period.key).format();
    const end = 'now';
    return { startDate: start, endDate: end };
  }
  if (period?.type === 'relative') {
    const { amount, unit } = period;
    const start = `${amount}${unit}`;
    const end = 'now';
    return { startDate: start, endDate: end };
  }
  return { startDate: period.startDate, endDate: period.endDate };
}

export function secondsToHHMM(secondsStr) {
  // Convert the input string to an integer
  const seconds = parseInt(secondsStr, 10);

  // Validate the input
  if (isNaN(seconds)) {
    return 'Invalid input';
  }

  // Calculate hours, minutes, and remaining seconds
  const hours = Math.floor(seconds / 3600);
  const remainingSecondsAfterHour = seconds % 3600;
  const minutes = Math.floor(remainingSecondsAfterHour / 60);
  const remainingSeconds = remainingSecondsAfterHour % 60;

  // Format the output string as HH:MM:SS
  const formattedHours = String(hours).padStart(2, '0');
  const formattedMinutes = String(minutes).padStart(2, '0');
  const formattedSeconds = String(remainingSeconds).padStart(2, '0');

  return `${formattedHours}:${formattedMinutes}:${formattedSeconds}`;
}
