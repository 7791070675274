import { useNavigate } from 'react-router-dom';
import { useApiWithFlash } from '../../../utils/hooks/useApiWithFlashInstance';
import EditSurveyForm from '../form';
import { SurveyData } from '../types';

export default function SurveyCreate() {
  // Hooks
  const navigate = useNavigate();
  const { handleApiWithFlash } = useApiWithFlash();

  // Handle form submission
  async function onSubmitForm(survey: SurveyData) {
    const { feature, ...surveyData } = survey;
    const response = await handleApiWithFlash(`/surveys/${feature}`, 'POST', {
      successMessage: `Successfully created Survey group ${feature}`,
      errorMessage: `Error creating Survey group ${feature}`,
      data: surveyData,
    });

    if (response) {
      navigate('/surveys');
    }
  }

  return <EditSurveyForm {...{ onSubmitForm }} />;
}
