export const FILTERING_PROPERTIES = [
    {
        propertyLabel: 'Resource',
        key: 'feature',
        groupValuesLabel: 'Resource values',
        operators: [':', '!:', '=', '!='] as const,
    },
    {
        propertyLabel: 'Description',
        key: 'description',
        groupValuesLabel: 'Description',
        operators: [':', '!:', '=', '!='] as const,
    },
    {
        propertyLabel: 'Type',
        key: 'type',
        groupValuesLabel: 'Type',
        operators: [':', '!:', '=', '!='] as const,
    }
] as const;
