import _ from 'lodash';

export default function updateMessageWithLang(array, keyValue, newValue, keyLang) {
    // Create a deep copy of the array to ensure no direct mutations
    const clonedArray = _.cloneDeep(array);

    const result = _.find(clonedArray, {key: keyValue});
    if (result) {
        const message = _.find(result.message, {lang: keyLang});
        if (message) {
            message.message[0].children[0].text = newValue;
        }
    }

    return clonedArray; // Return the updated copy
}
